<template>
  <div>
    <b-card
      style="max-width: 100%"
      class="text-center slide-right cursor-pointer"
      @click="$emit('click')"
    >
      <div
        class="
          d-flex
          flex-md-row flex-column
          align-items-center align-items-md-stretch
        "
      >
        <div
          class="
            col-md-4
            col-xl-3
            px-0
            pr-md-2
            d-flex
            justify-content-center
            align-items-center
            card-image-container
          "
        >
          <div v-if="item.image || item.thumbnail">
            <img
              v-show="canShowImage"
              v-bind="mainProps"
              :src="item.thumbnail ? item.thumbnail : item.image"
              alt="Image"
              class="rounded job-card-image"
              @load="onImgLoad"
            >
            <img
              v-if="!canShowImage"
              class="rounded job-card-image"
              src="@/assets/images/cards/video_card_image.png"
              alt=""
            >
          </div>
          <img
            v-else
            class="rounded job-card-image"
            src="@/assets/images/cards/video_card_image.png"
            alt=""
          >
        </div>
        <div class="col-md-8 col-xl-9 d-flex flex-column px-0 justify-content-between">
          <div>
            <div
              class="
                flex-column flex-lg-row
                d-flex
                justify-content-between
                align-items-start
                m-0
                p-0
                w-100
              "
            >
              <h4 class="font-weight-bolder text-dark text-md-left w-100 mt-1 mt-mb-0">
                {{ item.title }}
              </h4>
            </div>
            <div
              class="
                flex-column flex-lg-row
                d-flex
                justify-content-between
                align-items-start
                m-0
                p-0
                w-100
                mb-auto\
              "
            >
              <h5
                v-if="item.description"
                class="
                  text-left
                  w-100
                  ellipsis-2-line
                  mb-1
                  description-no-style
                "
                v-html="item.description"
              />
              <h5
                v-if="item.body"
                class="
                  text-left
                  w-100
                  ellipsis-2-line
                  mb-1
                  description-no-style
                "
                v-html="item.body"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <img
                v-if="false"
                alt=""
                class="workplace-image mr-1 h5"
              >
            </div>
            <div>
              <h5 class="text-muted mt-1 mb-0">
                {{ item.created_at | transformDate }}
              </h5>
              <h5 class="text-muted mb-0">
                {{ $t("Published") }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
  },
  filters: {
    transformDate(obj) {
      return moment(obj).format('DD.MM.YYYY')
    },
  },
  props: {
    // eslint-disable-next-line
    item: {
      type: Object,
    },
    canShowImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      types: {
        full_time: this.$t('Full time'),
        substitude: this.$t('Substitude'),
      },
      mainProps: {
        center: true,
        blank: true,
        blankHeight: '100%',
        blankWidth: '100%',
        blankColor: '#f8f9fa',
        fluid: true,
      },
    }
  },
  computed: {
    workplace() {
      if (
        this.job.attributes.workplace_situation_group === 'general_practice'
      ) {
        return this.job.attributes.workplace_situation.clinic
      }
      if (this.job.attributes.workplace_situation_group === 'hospital') {
        return this.job.attributes.workplace_situation.hospital
      }
      if (this.job.attributes.workplace_situation_group === 'other') {
        return this.job.attributes.workplace_situation.custom_workplace
      }
      return null
    },
  },
  methods: {
    openJobDetails() {
      if (this.is_own) {
        this.$router.push(`/my_jobs/details/${this.job.id}`)
      } else {
        this.$router.push(`/jobs/details/${this.job.id}`)
      }
    },
    onImgLoad() {
      this.$emit('onImgLoad')
    },
  },
}
</script>
